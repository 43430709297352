/* --------------------------------------
@Author: ThemeMascot
@URL: http://themeforest.net/user/ThemeMascot
----------------------------------------- */
/*
 * Dark.less
 * -----------------------------------------------
*/
@import (reference) "less-petcare/variables.less";

.dark {
	
	body& {
	    background-color: #1e1e1e !important;
	}

	h1, h2, h3, h4, h5, h6,
	.h1, .h2, .h3, .h4, .h5, .h6 {
	  color: lighten(@headings-color,50%);
	  a {
	  	color: lighten(@headings-color,50%);
	  }
	}
	.btn.focus, .btn:focus, .btn:hover {
	  color: @black-666;
	}
	.btn-theme-colored.focus, .btn-theme-colored:focus, .btn-theme-colored:hover {
	  color: @gray-lighter;
	}
	.divider-dark {
	    background-color: darken(@white-base,85.5%) !important;
	}

	/*Bacground Color*/
	.bg-deep {
	    background-color: darken(@white-base,90%) !important;
	}
	.bg-light {
	    background-color: darken(@white-base,87.5%) !important;
	}
	.bg-lighter {
	    background-color: darken(@white-base,85%) !important;
	}
	.bg-lightest {
	    background-color: darken(@white-base,80%) !important;
	}
	.bg-white-light {
	    background-color: @black-333 !important;
	}
	.bg-white {
	    background-color: @black !important;
	}
	

	/*Bacground Transparent Color*/
	.bg-deep-transparent {
		background-color: fade(@black, 90%);
	}
	.bg-light-transparent {
		background-color: fade(@black, 80%);
	}
	.bg-lighter-transparent {
	    background-color: fade(@black, 70%);
	}
	.bg-lightest-transparent {
	    background-color: fade(@black, 50%);
	}
	.bg-very-lightest-transparent {
		background-color: fade(@black, 20%);
	}

	/*Text Color*/
	.text-black-333 {
		color: lighten(@headings-color,50%) !important;
	}	
	.text-white-light {
	    color: darken(@white-base,70%) !important;
	}

	/*Layer Overlay*/
	.layer-overlay.overlay-light::before {
		background-color: fade(darken(@white-base,87%), 80%);
	}
	.layer-overlay.overlay-lighter::before {
		background-color: fade(darken(@white-base,87%), 70%);
	}
	.layer-overlay.overlay-deep::before {
		background-color: fade(darken(@white-base,87%), 90%);
	}

	/* border */
	.border-1px,
	.border-2px,
	.border-3px,
	.border-4px,
	.border-5px,
	.border-6px,
	.border-7px,
	.border-8px,
	.border-9px,
	.border-10px {
		border-color: @black-333 !important;
	}
	.border-right {
	    border-right: 1px solid @black-333;
	}

	/*Custom Theme Styles*/
	hr {
		border-color:  @black-333;
	}
	.blog-posts .post.style1 {
		border-color: darken(@white-base,90%) !important;
	}
	.title-border{
		border-color: @black-333 !important;
	}
	.event {
		border-color: @black-333 !important;
		box-shadow: unset;
	}
    .team-carousel-1col .team-thum {
	    border-color: darken(@white-base,90%) !important;
	}
	.pricing-table.style1 .pricing-icon i {
	    border-color: darken(@white-base,90%) !important;
	}
	.pricing-table .table-list li {
	    border-bottom-color: @black-444;
	}
	.pricing-table.pricing-style2 .table-list li {
	    border-bottom: none;
	}
	.pricing-table.pricing-style2 .package-type {
	    color: @black-111 !important;
	}
	.service-icon-box a {
	  background-color: fade(darken(@white-base,87%), 60%) !important;
	}
	.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev,
	.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
	  background: darken(@white-base,75%) !important;
	}
	.bx-controls .bx-controls-direction > a {
	  background: darken(@white-base,75%) !important;
	}
	.funfact.bg-white {
	  background: darken(@white-base,85%) !important;
	}
	.funfact.style-1:hover .funfact-title {
	  color: @black-555 !important;
	}
	.separator::before, .separator::after {
	  border-bottom: 1px solid @black-333;
	}
	.register-form .form-control {
	  background: fade(@black, 20%);
	  border-color: @black-333;
	}
	.login-form {
	  border: 1px solid @black-333;
	}
	.form-control {
	  background: fade(@black, 20%);
	  border-color: @black-333;
	}
	.login-form .form-control,
	.register-form .form-control {
	  background: fade(@black, 20%);
	  border-color: @black-555;
	}
	.login-form.login-form-transparent {
	  background-color: @black-222;
	}
	.tab-content {
	  border: 1px solid @black-333;
	}
	.services-tab .nav-tabs,
	.services-tab .nav-tabs > li {
	  border-color: darken(@white-base,90%) !important;
	}
	.services-tab .nav-tabs > li > a {
	  background-color: darken(@white-base,70%) !important;
	}
	.header-nav .header-nav-wrapper.scroll-to-fixed-fixed.scroll-to-fixed-fixed {
	  border-bottom-color: @black-444;
	}
	.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
	  color: @gray-base;
	}	
	.nav-tabs > li > a:hover,
	.nav-tabs > li.active a {
	  border-color: @black-333;
	}
	.nav-sidebar li {
	  background-color: darken(@white-base,86%) !important;
	}
	.products .product {
	  border: 1px solid @black-333;
	}

	/* toggle accordion */
	.panel-group {
		.panel {
			background-color: darken(@white-base,85%) !important;

			.panel-title {
				a {
					background-color: fade(darken(@white-base,87%), 50%) !important;

					&:hover {
						color: @white-f5 !important;
					}
				}

				a.active {
					background-color: fade(darken(@white-base,89%), 80%) !important;
					border-bottom-color: fade(darken(@white-base,91%), 80%) !important;
					color: @white-f5 !important;

					&:after {
					  background-color: @black-333 !important;
					}
				}
			}

			.panel-content {
				background-color: fade(darken(@white-base,89%), 80%) !important;
			}
			
		}
		
		&.transparent .panel {
			background-color: transparent !important;

			.panel-title {
				a {
					background-color: transparent !important;
				}

				a.active {
					background-color: transparent!important;
					border-bottom-color: transparent !important;

					&:after {
					  background-color: transparent !important;
					}
				}
			}
		}
	}

	/*Blog Style*/
	.post .entry-content {
		background-color: darken(@white-base,84.5%) !important;
	}

	.fc-day-number {
		color: lighten(@headings-color,50%);
	}

	.header-nav .header-nav-wrapper .menuzord {
	  	background: transparent;
	}
	.menuzord-menu > li > a {
	  color: @black-666;
	}
	
	/* boxed-layout */
	&.boxed-layout section {
		background: darken(@white-base,87.5%);
	}

	/* Feature Icon-box */
	.feature-icon-box {
	  border: 1px solid @black-333;
	}

	/* Opening Hourse */
	.opening-hourse ul li {
	  border-bottom: 1px solid @black-333;
	}

	/* story */
	.each-story .story-details {
	    background: @black-222;
	}

	/* best-people */
	.best-people .info {
		background-color: fade(@black, 93.5%);
	}
	
	.couple .couple-details {
		background-color: fade(@black, 80%);
	 }
	
	/* Blog */
	.blog-post  {
		.post {
			background-color: @black-111;
			box-shadow: unset;
        }
    }

    /* Testimonial */
    .testimonial-carousel {
        .item {
	        .testimonial-wrapper {
		      background: @black-222 url("../images/bg-flower6.png") no-repeat scroll right bottom;
	        }
        }
    }
    .portfolio-filter a {
      border: 1px solid @black-333;
    }
    .img-thumbnail {
      background-color: @black-333;
      border: 1px solid @black-333;
    }
    .pagination > li > a, .pagination > li > span {
	  background-color: @black-444;
	  border: 1px solid @black-555;
	}
	
	.list-dashed > article {
	  border-bottom: 1px dashed @black-555 !important;
	}
	
	.widget .search-button {
      border: 1px solid @black-333;
    }
    .widget .list-border li {
      border-bottom: 1px dashed @black-333;
    }
    .widget .tags a {
      border: 1px solid @black-333;
    }
    
	.title-pattern > span {
	  background: #1e1e1e;
	}
	    	
}